<template>
    <div class="home">
        <v-row justify="space-between">
            <v-col xs="10" md="5">

                <TitleText title="Uw auto"
                           text="Het onderstaande kenteken is aan uw gegevens gekoppeld.<br/>Mocht dit niet kloppen kunt
                           u dit wijzigen."></TitleText>

                <CarInfo></CarInfo>

                <TitleText class="mt-10" title="Uw e-mailadres"
                           text="Wij hebben uw e-mailadres nodig om uw onderhouds-historie die bij ons bekend is op te
                           vragen en uw afspraak te kunnen bevestigen."></TitleText>

                <v-form ref="emailAddressForm" @submit.prevent="nextPage(true)">
                    <v-row>
                        <v-col>
                            <v-text-field
                                ref="emailAddress"
                                name="emailAddress"
                                label="E-mailadres"
                                v-model="mailAddress"
                                autofocus
                                :rules="[rules.required, rules.email]"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-btn
                                color="primary"
                                width="100%"
                                height="41px"
                                class="rounded-lg"
                                type="submit"
                            >
                                Ga verder
                            </v-btn>

                            <v-spacer class="mb-2"></v-spacer>

                            <v-btn
                                color="secondary"
                                type="button"
                                width="100%"
                                height="41px"
                                class="rounded-lg darkgrey--text"
                                @click="previousPage()"
                            >
                                Ga terug
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>

            <v-divider vertical class="d-none d-sm-flex"></v-divider>

            <v-col md="5" class="hidden-xs-only">
                <CarImage></CarImage>

                <WhatsApp></WhatsApp>
            </v-col>
        </v-row>

        <v-dialog v-model="nawDialog" persistent max-width="500">
            <v-card>
                <v-card-title>
                    <h6 class="text-uppercase darkblue--text">
                        Uw gegevens zijn niet gevonden
                    </h6>
                    <v-card-actions class="ml-auto">
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card-title>

                <v-card-text>
                    De combinatie e-mailadres en kenteken is bij ons niet bekend. Controleer de ingevulde gegevens.
                    Wellicht staat u met een ander e-mailadres bij ons geregistreerd.
                    Klik op “Ga verder” indien de gegevens kloppen.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.stop="continueFromDialog" @keydown.enter="continueFromDialog">
                        Ga verder
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import CarImage from "@/components/yourcar/CarImage";
import CarInfo from "@/components/yourcar/CarInfo";
import TitleText from "@/components/general/TitelText";
import WhatsApp from "@/components/general/WhatsApp";
import {mapGetters} from "vuex";

export default {
    "name": "YourCar",
    "components": {
        CarImage,
        TitleText,
        CarInfo,
        WhatsApp
    },
    data () {
        return {
            "nawDialog": false,
            "rules": {
                "required": (value) => Boolean(value) || "Verplicht veld.",
                "email": (value) => !value || (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/u).test(value) || "E-mail is niet correct."
            }
        };
    },
    async created () {
        const filter = {
            "make": this.$store.state.car.currentCarInfo.make,
            "modelId": this.$store.state.car.currentCarInfo.modelId,
            "modelYear": this.$store.state.car.currentCarInfo.modelYear,
            "motorName": this.$store.state.car.currentCarInfo.motorName,
            "fuelType": this.$store.state.car.currentCarInfo.fuelType,
            "transmission": this.$store.state.car.currentCarInfo.transmission,
            "licensePlate": this.$store.state.car.Appointment.Fields.RegistrationNumber,
            "km": parseInt(
                this.$store.state.car.Appointment.Fields.Mileage,
                10
            )
        };
        await this.$store.dispatch(
            "car/getMaintenanceProducts",
            filter
        );
        await this.$store.dispatch(
            "car/getActionProducts",
            filter
        );
        await this.$store.dispatch(
            "car/updatePathValue",
            {
                "object": "Appointment.Fields.FormattedLicensePlate",
                "value": this.formattedLicensePlate
            }
        );
        await this.$store.dispatch(
            "car/updatePathValue",
            {
                "object": "Appointment.Fields.FormattedWorkshopLicensePlate",
                "value": this.formattedWorkshopLicensePlate
            }
        );
    },
    "methods": {
        closeDialog () {
            this.nawDialog = false;
        },
        continueFromDialog () {
            this.nawDialog = false;
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    "object": "existingCustomer",
                    "value": false
                }
            );
            this.$router.push({"name": "MaintenanceAdviceOptions"});
        },
        // eslint-disable-next-line max-lines-per-function,consistent-return
        async nextPage (changeAdvice) {
            await this.$store.dispatch(
                "car/updatePathValue",
                {
                    "object": "doAdvice",
                    "value": changeAdvice
                }
            );
            const filter = {
                "advice": changeAdvice,
                "make": this.$store.state.car.currentCarInfo.make,
                "modelId": this.$store.state.car.currentCarInfo.modelId,
                "modelYear": this.$store.state.car.currentCarInfo.modelYear,
                "motorName": this.$store.state.car.currentCarInfo.motorName,
                "fuelType": this.$store.state.car.currentCarInfo.fuelType,
                "transmission": this.$store.state.car.currentCarInfo.transmission,
                "licensePlate": this.$store.state.car.Appointment.Fields.RegistrationNumber,
                "apkExpireDate": this.$store.state.car.currentRdwData.vervaldatum_apk_dt,
                "km": parseInt(
                    this.$store.state.car.Appointment.Fields.Mileage,
                    10
                )
            };
            await this.$store.dispatch(
                "car/getMaintenanceProducts",
                filter
            );
            await this.$store.dispatch(
                "car/getActionProducts",
                filter
            );
            if (!this.$refs.emailAddressForm.validate()) {
                return false;
            }
            if (!this.existingCustomer) {
                await this.$router.push({"name": "MaintenanceAdviceOptions"});
                return false;
            }
            await this.$store.dispatch(
                "car/searchNaw",
                this.mailAddress
            )
                // eslint-disable-next-line max-lines-per-function
                .then(async (response) => {
                    if (response.status === 204) {
                        throw Error("Not found");
                    }

                    if (typeof (response) !== "undefined" && typeof (response.data) !== "undefined") {
                        const data = response.data;

                        const contactInfoFields = [
                            {
                                "object": ["Appointment.Fields.CompanyName"],
                                "value": data.naam
                            }, {
                                "object": ["Appointment.Fields.Name"],
                                "value": data.naam2
                            }, {
                                "object": ["Appointment.Fields.Salutation"],
                                "value": data.aanhef
                            }, {
                                "object": ["Appointment.Fields.Street"],
                                "value": data.adres
                            }, {
                                "object": ["Appointment.Fields.Gender"],
                                "value": data.geslacht
                            }, {
                                "object": ["Appointment.Fields.HouseNumber"],
                                "value": data.huisnr
                            }, {
                                "object": ["Appointment.Fields.ZipCode"],
                                "value": data.postcode
                            }, {
                                "object": ["Appointment.Fields.City"],
                                "value": data.plaats
                            }, {
                                "object": ["Appointment.Fields.Phone"],
                                "value": data.telnrmobiel
                            }, {
                                "object": ["Appointment.Fields.Email"],
                                "value": data.emailadres
                            }, {
                                "object": ["currentContactInfo.showNewsLetterCheckbox"],
                                "value": (typeof (data.aftersalesemailable) !== "undefined" && data.aftersalesemailable.trim() !== "1")
                            }, {
                                "object": ["currentContactInfo.pst"],
                                "value": data.pst
                            }
                        ];

                        for (const field of contactInfoFields) {
                            this.$store.dispatch(
                                "car/updatePathValue",
                                {
                                    "object": field.object,
                                    "value": field.value
                                }
                            );
                        }
                    }
                    await this.$router.push({"name": "MaintenanceAdviceOptions"});
                })
                .catch(() => {
                    this.nawDialog = true;
                });
        },
        previousPage () {
            this.$router.push({"name": "Home"});
        }
    },
    "computed": {
        ...mapGetters({
            "formattedLicensePlate": "car/formattedLicensePlate",
            "formattedWorkshopLicensePlate": "car/formattedWorkshopLicensePlate"
        }),
        existingCustomer () {
            return this.$store.state.car.existingCustomer;
        },
        "mailAddress": {
            get () {
                return this.$store.state.car.Appointment.Fields.Email;
            },
            set (value) {
                this.$store.dispatch(
                    "car/updatePathValue",
                    {
                        "object": ["Appointment.Fields.Email"],
                        value
                    }
                );
            }
        }
    }
};
</script>

<style>
    .ml-auto {
        margin-left: auto;
    }
</style>
