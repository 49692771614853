import ActionPage from "@/views/ActionPage.vue";
import AppointmentType from "@/views/AppointmentType.vue";
import CheckList from "@/views/CheckList";
import ContactInfo from "@/views/ContactInfo";
import DamageRecovery from "@/views/DamageRecovery";
import DateTime from "@/views/DateTime";
import Establishments from "@/views/Establishments";
import Home from "@/views/Home.vue";
import MaintenanceAdvice from "@/views/MaintenanceAdvice.vue";
import MaintenanceAdviceOptions from "@/views/MaintenanceAdviceOptions";
import OptionWindowDamageRecovery from "@/views/OptionWindowDamageRecovery";
import PhotosDamageRecovery from "@/views/PhotosDamageRecovery";
import PlannerDamageRecovery from "@/views/PlannerDamageRecovery";
import Summary from "@/views/Summary";
import Thanks from "@/views/Thanks";
import VehicleReplacement from "@/views/VehicleReplacement";
import Vue from "vue";
import VueRouter from "vue-router";
import Wait from "@/views/Wait";
import WindowDamageRecovery from "@/views/WindowDamageRecovery";
import YourCar from "@/views/YourCar.vue";

Vue.use(VueRouter);

const routes = [
    {
        "path": "/acties",
        "name": "ActionPage",
        "component": ActionPage,
        "meta": {
            "title": "Acties"
        }
    },
    {
        "path": "/afspraaktype",
        "name": "AppointmentType",
        "component": AppointmentType,
        "meta": {
            "title": "Werkzaamheden"
        }
    },
    {
        "path": "/contactinfo",
        "name": "ContactInfo",
        "component": ContactInfo,
        "meta": {
            "title": "Samenvatting"
        }
    },
    {
        "path": "/datumtijd",
        "name": "DateTime",
        "component": DateTime,
        "meta": {
            "title": "Locatie & tijd"
        }
    },
    {
        "path": "/",
        "name": "Home",
        "component": Home,
        "meta": {
            "title": "Kenteken"
        }
    },
    {
        "path": "/onderhoudsadvies",
        "name": "MaintenanceAdvice",
        "component": MaintenanceAdvice,
        "meta": {
            "title": "Werkzaamheden"
        }
    },
    {
        "path": "/onderhoudsadvies/opties",
        "name": "MaintenanceAdviceOptions",
        "component": MaintenanceAdviceOptions,
        "meta": {
            "title": "Werkzaamheden"
        }
    },
    {
        "path": "/onderhoudsadvies/opties/geen-advies",
        "name": "MaintenanceAdviceNoOptions",
        "component": MaintenanceAdviceOptions,
        "meta": {
            "title": "Werkzaamheden"
        }
    },
    {
        "path": "/optie/ruitschadeherstel",
        "name": "OptionWindowDamageRecovery",
        "component": OptionWindowDamageRecovery,
        "meta": {
            "title": "Werkzaamheden"
        }
    },
    {
        "path": "/photos/schadeherstel",
        "name": "PhotosDamageRecovery",
        "component": PhotosDamageRecovery,
        "meta": {
            "title": "Werkzaamheden"
        }
    },
    {
        "path": "/planner/schadeherstel",
        "name": "PlannerDamageRecovery",
        "component": PlannerDamageRecovery,
        "meta": {
            "title": "Locatie & tijd"
        }
    },
    {
        "path": "/checklist",
        "name": "CheckList",
        "component": CheckList,
        "meta": {
            "title": "Werkzaamheden"
        }
    },
    {
        "path": "/ruitschadeherstel",
        "name": "WindowDamageRecovery",
        "component": WindowDamageRecovery,
        "meta": {
            "title": "Werkzaamheden"
        }
    },
    {
        "path": "/samenvatting",
        "name": "Summary",
        "component": Summary,
        "meta": {
            "title": "Samenvatting"
        }
    },
    {
        "path": "/schadeherstel",
        "name": "DamageRecovery",
        "component": DamageRecovery,
        "meta": {
            "title": "Werkzaamheden"
        }
    },
    {
        "path": "/bedankt",
        "name": "Thanks",
        "component": Thanks,
        "meta": {
            "title": "Bedankt!"
        }
    },
    {
        "path": "/vestigingen",
        "name": "Establishments",
        "component": Establishments,
        "meta": {
            "title": "Locatie & tijd"
        }
    },
    {
        "path": "/wachten",
        "name": "Wait",
        "component": Wait,
        "meta": {
            "title": "Locatie & tijd"
        }
    },
    {
        "path": "/uwauto",
        "name": "YourCar",
        "component": YourCar,
        "meta": {
            "title": "Kenteken"
        }
    },
    {
        "path": "/vervangendvervoer",
        "name": "VehicleReplacement",
        "component": VehicleReplacement,
        "meta": {
            "title": "Vervangend vervoer"
        }
    }
];

const router = new VueRouter({
    "mode": "history",
    "base": process.env.VUE_APP_BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    window.scrollTo(
        0,
        0
    );
    localStorage.setItem(
        "jwt",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJpYXQiOjE2NDk4NTQzMTMsImV4cCI6NDgwNTYxNDMxM30.teyEInAR1zgsvVLhDLd7Us_j4QTGt0NHX0_B866E_GY"
    );
    return next();
});

export default router;

