<template>
  <v-row v-if="carInfo !== {}"
         no-gutters>
    <v-col cols="6"><strong>Merk:</strong></v-col>
    <v-col cols="6">{{ carInfo.make }}</v-col>

    <v-col cols="6"><strong>Model:</strong></v-col>
    <v-col cols="6">{{ carInfo.model }}</v-col>

    <v-col cols="6"><strong>Bouwjaar:</strong></v-col>
    <v-col cols="6">{{ carInfo.modelYear }}</v-col>

    <v-col cols="6"><strong>Kenteken:</strong></v-col>
    <v-col cols="6">{{ formattedLicensePlate }}</v-col>

    <v-col cols="6"><strong>Kilometerstand:</strong></v-col>
    <v-col cols="6">{{ licensePlateNumber }} km</v-col>
  </v-row>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    "name": "CarInfo",
    "components": {},
    "computed": {
        ...mapGetters({
            "carInfo": "car/currentCarInfo",
            "formattedLicensePlate": "car/formattedLicensePlate"
        }),
        licensePlateNumber () {
            return this.$store.state.car.Appointment.Fields.Mileage;
        }
    }
};
</script>
