<template>
    <div>
        <v-sheet outlined color="darkblue" class="mb-5">
            <v-card tile>
                <v-card-subtitle class="text-uppercase darkblue white--text">
                    <v-icon color="white">mdi-clock</v-icon>
                    Tijdens de werkzaamheden
                    <button v-if="this.toggleShown" class="float-right" @click="toggleCard()">
                        <v-icon color="white" v-show="!cardShown">mdi-chevron-down</v-icon>
                        <v-icon color="white" v-show="cardShown">mdi-chevron-up</v-icon>
                    </button>
                </v-card-subtitle>
                <v-card-text class="dark--text" v-show="cardShown">
                    <p v-if="this.waitForMaintenance === null">
                        Maak eerst een keuze.
                    </p>
                    <p v-else-if="this.waitForMaintenance">
                        Ik blijf wachten.
                    </p>
                    <p v-else>Ik blijf niet wachten.</p>
                </v-card-text>
            </v-card>
        </v-sheet>
    </div>
</template>

<script>

import SidebarCard from "@/components/abstract/SidebarCard";

export default {
    "extends": SidebarCard,
    "name": "SidebarWait",
    "computed": {
        waitForMaintenance () {
            return this.$store.state.car.waitForMaintenance;
        }
    }
};
</script>

<style scoped>
.v-card__subtitle {
    padding: 10px;
}
</style>
