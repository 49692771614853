<template>
    <v-menu
        v-if="text"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        max-width="400"
        content-class="elevation-0">
        <template v-slot:activator="{ on, attrs }">
            <v-icon size="17"
                    class="ml-1"
                    color="darkblue"
                    v-bind="attrs"
                    v-on="on">
                mdi-information
            </v-icon>
        </template>
        <v-card light flat class="ma-2" outlined>
            <v-card-text v-html="text"></v-card-text>
        </v-card>
    </v-menu>
</template>

<script>

export default {
    "name": "Tooltip",
    "props": {
        "text": {
            "type": String,
            "default": ""
        }
    }
};

</script>
