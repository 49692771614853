import Vue from "vue";
import Vuex from "vuex";
import car from "./modules/car";
import cms from "./modules/cms";
import establishmentInfo from "./modules/establishmentInfo";
import reviews from "./modules/reviews";
import session from "./modules/session";

Vue.use(Vuex);

export default new Vuex.Store({
    "modules": {
        car,
        cms,
        establishmentInfo,
        reviews,
        session
    },
    "strict": process.env.VUE_APP_NODE_ENV !== "production"
});
