<template>
    <div class="vehicleReplacementPage">
        <v-row>
            <v-col>
                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="VEHICLEREPLACEMENT"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col sm="7" xs="12" cols="12">
                        <v-row>
                            <v-col>
                                <TransportForm></TransportForm>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       height="41px"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>

                            <v-col align="right">
                                <v-btn class="rounded-lg text-uppercase"
                                       color="primary"
                                       type="button"
                                       height="41px"
                                       @click="nextPage()">
                                    Ga verder
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar class="col-sm-4 col-12"
                        :car="{show: true, toggle: true, showToggle: false}"
                        :maintenance="{show: true, toggle: true, showToggle: false}"
                        :location="{show: true}"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>

    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Sidebar from "@/components/maintenance/Sidebar";
import TransportForm from "@/components/forms/Transport";
import {mapGetters} from "vuex";

export default {
    "name": "VehicleReplacement",
    "components": {
        TransportForm,
        BreadCrumbs,
        Sidebar
    },
    "computed": {
        existingCustomer () {
            return this.$store.state.car.existingCustomer;
        },
        ...mapGetters({
            "nextActionProduct": "car/getNextActionProduct"
        })
    },
    "methods": {
        previousPage () {
            this.$router.push({"name": "DateTime"});
        },
        nextPage () {
            if (!this.existingCustomer) {
                this.$router.push({"name": "ContactInfo"});
            } else {
                this.$router.push({"name": "Summary"});
            }
        }
    }
};
</script>
