<template>
    <div class="page">

        <v-row>
            <v-col cols="10" offset="1">
                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="ACTIVITIES"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col md="7" xs="12">
                        <TitleText title="Onderhoudsadvies"
                                   text="Kijkend naar eerder onderhoud bij ons, de kilometerstand en het bouwjaar
                                   stellen wij onderstaand onderhoud voor:"></TitleText>

                        <v-divider></v-divider>

                        <v-row v-for="product in productList" :key="product.id">
                            <v-col>
                                <v-row
                                    justify="center" align="center">
                                    <v-col cols="1">
                                        <v-checkbox
                                            @change="changeChosenProduct(product.id, $event)"

                                            :input-value="isChosenProduct(product.id)"
                                            class="option-checkbox"></v-checkbox>
                                    </v-col>

                                    <v-col>
                                        <span v-html="product.label" class="text-body-2"></span>

                                        <Tooltip :text="product.tooltip" />

                                        <div class="mt-2 d-block text--sub" v-html="product.subtext"></div>
                                        <div class="mt-2 d-block text--sub text--ital" v-html="product.lastdate"></div>
                                    </v-col>

                                    <v-col cols="2">
                                        <Price :product="product" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-divider></v-divider>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row v-if="doAdvice">
                            <v-col>
                                <v-dialog v-model="extraTasksDialog" width="500" scrollable>
                                    <template v-slot:activator="{ on, attrs }">
                                        <sub
                                            v-bind="attrs"
                                            v-on="on">
                                            <v-icon>mdi-plus</v-icon>
                                            <span class="text-decoration-underline text-uppercase">
                                                Aanvullende werkzaamheden selecteren
                                            </span>
                                        </sub>
                                    </template>

                                    <v-card tile>
                                        <v-card-title class="text-uppercase darkblue white--text">
                                            Voeg werkzaamheden toe
                                        </v-card-title>

                                        <v-card-text>
                                            <v-checkbox v-for="product in unChosenProducts"
                                                        :key="product.id" class="extraTask"
                                                        @change="changeChosenProduct(product.id, $event)">
                                                <template v-slot:label>
                                                    <v-row>
                                                        <v-col>
                                                            <span v-html="product.label" class="text-body-2"></span>

                                                            <Tooltip :text="product.tooltip" />
                                                        </v-col>
                                                        <v-col align="right">
                                                            <Price :product="product" />
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-checkbox>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="darken-1"
                                                text
                                                @click.stop="extraTasksDialog = false"
                                            >
                                                Sluiten
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <TitleText title="Opmerkingen en reparatieverzoeken"></TitleText>

                                <v-divider class="mb-5"></v-divider>

                                <v-textarea
                                    outlined
                                    v-model="remarks"
                                    label="Zijn er nog bijzonderheden waar we rekening mee moeten houden?">
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       height="41px"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>
                            <v-col cols="6" align="right">
                                <v-btn class="rounded-lg text-uppercase"
                                       color="primary"
                                       :disabled="!this.$store.state.car.chosenProducts.length"
                                       type="button"
                                       height="41px"
                                       @click="nextPage()">
                                    Ga verder
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: true, showToggle: false}"
                        :maintenance="{show: true, toggle: true, showToggle: false}"
                        :location="{show: false}" class="hidden-xs-only"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>

    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Price from "@/components/product/Price";
import Sidebar from "@/components/maintenance/Sidebar";
import TitleText from "@/components/general/TitelText";
import Tooltip from "@/components/product/Tooltip";

import {mapGetters} from "vuex";

export default {
    "name": "MaintenanceAdviceOptions",
    "components": {
        Price,
        Tooltip,
        BreadCrumbs,
        Sidebar,
        TitleText
    },
    "methods": {
        previousPage () {
            this.$router.push({"name": "YourCar"});
        },
        nextPage () {
            const windowRepairProduct = this.$store.state.car.chosenProducts.find((item) => item.code.trim().toLowerCase() === "voorruitster");
            const windowReplaceProduct = this.$store.state.car.chosenProducts.find((item) => item.code.trim().toLowerCase() === "voorruitvervanging");
            if (typeof windowRepairProduct !== "undefined" || typeof windowReplaceProduct !== "undefined") {
                if (typeof (this.nextActionProduct) !== "undefined") {
                    this.$router.push({"name": "ActionPage"});
                } else {
                    this.$router.push({"name": "WindowDamageRecovery"});
                }
            } else {
                this.$router.push({"name": "OptionWindowDamageRecovery"});
            }
        },
        changeChosenProduct (id, event) {
            this.$store.dispatch(
                "car/changeChosenProduct",
                {id,
                    event}
            );
        }
    },
    "computed": {
        ...mapGetters({
            "productList": "car/productList",
            "chosenProducts": "car/chosenProducts",
            "unChosenProducts": "car/unChosenProducts",
            "isChosenProduct": "car/isChosenProduct",
            "nextActionProduct": "car/getNextActionProduct"
        }),
        doAdvice () {
            return this.$store.state.car.doAdvice;
        },
        "remarks": {
            get () {
                return this.$store.state.car.Appointment.AppointmentInfo.Remarks;
            },
            set (value) {
                this.$store.dispatch(
                    "car/changeChosenProduct",
                    {
                        "id": 56879,
                        "event": value
                    }
                );
                this.$store.dispatch(
                    "car/updatePathValue",
                    {
                        "object": "Appointment.AppointmentInfo.Remarks",
                        value
                    }
                );
            }
        }
    },
    data () {
        let products = [];
        if (this.$store.state.car.doAdvice) {
            products = this.unChosenProducts;
        }
        return {
            "extraTasksDialog": false,
            "extraTasks": products
        };
    }
};
</script>

<style scoped>
.extraTask {
    margin-top: 0px;
    padding-top: 0px;
}

>>>.option-checkbox > .v-input__control {
    width: 80% !important;
}
.text--sub {
    font-size: 75%;
}
.text--ital {
    font-style: italic;
}
</style>
