<template>
    <v-sheet :height="this.height" outlined color="darkblue" class="mb-5">
        <v-card :height="this.height" tile>
            <v-card-subtitle class="text-uppercase darkblue white--text">
                <v-icon color="white">mdi-car</v-icon>
                Uw auto
                <button v-if="this.toggleShown" class="float-right" @click="toggleCard()">
                    <v-icon color="white" v-show="!cardShown">mdi-chevron-down</v-icon>
                    <v-icon color="white" v-show="cardShown">mdi-chevron-up</v-icon>
                </button>
            </v-card-subtitle>
            <v-card-text class="mt-3 dark--text" v-show="cardShown">
                <v-row dense>
                    <v-col>Kenteken:</v-col>
                    <v-col align="right">{{ formattedLicensePlate }}</v-col>
                </v-row>
                <v-row dense>
                    <v-col>Merk:</v-col>
                    <v-col align="right">{{ carInfo.make }}</v-col>
                </v-row>
                <v-row dense>
                    <v-col>Model:</v-col>
                    <v-col align="right">{{ carInfo.model }}</v-col>
                </v-row>
                <v-row dense>
                    <v-col>Bouwjaar:</v-col>
                    <v-col align="right">{{ carInfo.modelYear }}</v-col>
                </v-row>
                <v-row dense>
                    <v-col>Kilometerstand:</v-col>
                    <v-col align="right">{{ licensePlateNumber }} km</v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-sheet>
</template>

<script>

import SidebarCard from "@/components/abstract/SidebarCard";
import {mapGetters} from "vuex";

export default {
    "extends": SidebarCard,
    "props": ["showToggle", "height"],
    "name": "SidebarYourCar",
    "computed": {
        ...mapGetters({
            "carInfo": "car/currentCarInfo",
            "formattedLicensePlate": "car/formattedLicensePlate"
        }),
        licensePlateNumber () {
            return this.$store.state.car.Appointment.Fields.Mileage;
        }
    }
};
</script>

<style scoped>
.v-card__subtitle {
    padding: 10px;
}
</style>
