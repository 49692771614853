<template>
    <div class="home">

        <TitleText title="Samenvatting"></TitleText>

        <v-row justify="space-between">
            <v-col xs="10" md="7">
                <v-row class="d-flex" style="flex-direction:row">
                    <v-col cols="12" md="6" class="flex-grow-1">
                        <v-sheet outlined height="100%" color="darkblue" class="mb-1">
                            <v-card height="100%" tile>
                                <v-card-subtitle class="text-uppercase darkblue white--text">
                                    <v-icon color="white">mdi-account</v-icon>
                                    Contactgegevens
                                </v-card-subtitle>
                                <v-card-text class="mt-3 dark--text">
                                    <v-row dense>
                                        <v-col>{{ appointmentFields.CompanyName }}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col>{{ appointmentFields.Salutation }}
                                        {{ appointmentFields.Infix }}
                                        {{ appointmentFields.Name }}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col>
                                            {{ appointmentFields.Street }} {{ appointmentFields.HouseNumber }}
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col>{{ appointmentFields.ZipCode }} {{ appointmentFields.City }}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col>E: {{ appointmentFields.Email }}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col>T: {{ appointmentFields.Phone }}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col></v-col>
                                        <v-col>
                                            <a @click.stop="contactDialog = true" class="float-right darkgrey--text">
                                                wijzig
                                                <v-icon size="15">mdi-square-edit-outline</v-icon>
                                            </a>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-sheet>
                    </v-col>
                    <v-col class="flex-grow-1">
                        <SidebarYourCar :show-toggle="false" height="100%"></SidebarYourCar>
                    </v-col>
                </v-row>

                <v-row
                    v-if="chosenProducts.length > 0">
                    <v-col>
                        <SidebarMaintenance
                            :show-toggle="false"
                            :show-price-sub-text="true"
                        ></SidebarMaintenance>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-sheet outlined height="100%" color="darkblue" class="mb-1">
                            <v-card height="100%" tile>
                                <v-card-subtitle class="text-uppercase darkblue white--text">
                                    <v-icon color="white">mdi-calendar-month</v-icon>
                                    Afspraak
                                </v-card-subtitle>
                                <v-card-text class="mt-3 dark--text">
                                    <v-row dense>
                                        <v-col cols="5">Vestiging:</v-col>
                                        <v-col align="right">{{ currentEstablishmentName }}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="5">Datum:</v-col>
                                        <v-col align="right">{{ maintenanceDate }}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="5">Tijd:</v-col>
                                        <v-col align="right">{{ maintenanceTime }} uur</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col>
                                            <a @click.stop="dateDialog = true" class="float-right darkgrey--text">
                                                wijzig
                                                <v-icon size="15">mdi-square-edit-outline</v-icon>
                                            </a>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-sheet>
                    </v-col>
                    <v-col>
                        <v-sheet outlined height="100%" color="darkblue" class="mb-1">
                            <v-card height="100%" tile>
                                <v-card-subtitle class="text-uppercase darkblue white--text">
                                    <v-icon color="white">mdi-clock-time-four</v-icon>
                                    Vervangend vervoer
                                </v-card-subtitle>
                                <v-card-text class="mt-3 dark--text">
                                    <v-row>
                                        <v-col v-if="!transport.transport">
                                            Nee, ik blijf wachten op de werkzaamheden
                                        </v-col>
                                        <v-col v-else>
                                            Ja,
                                            <span v-if="transport.type === 1">een elektrische fiets</span>
                                            <span v-else-if="transport.type === 2">de haal- en brengservice</span>
                                            <span v-else-if="transport.type === 3">een vervangende auto</span>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col>
                                            <a @click.stop="transportDialog = true" class="float-right darkgrey--text">
                                                wijzig
                                                <v-icon size="15">mdi-square-edit-outline</v-icon>
                                            </a>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-sheet>
                    </v-col>
                </v-row>

            </v-col >

            <v-col xs="10" md="4">
                <v-row>
                    <v-col>
                        <v-sheet outlined height="100%" color="darkblue" class="mb-1">
                            <v-card height="100%" tile>
                                <v-card-subtitle class="text-uppercase darkblue white--text">
                                    <v-icon color="white">mdi-chat</v-icon>
                                    Opmerkingen
                                </v-card-subtitle>
                                <v-card-text class="mt-3">
                                    <v-textarea
                                        v-model="remarks"
                                        height="100%"
                                        full-width
                                        label="Voeg opmerkingen toe..."></v-textarea>
                                </v-card-text>
                            </v-card>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                      v-if="appointmentFields.showNewsLetterCheckbox"
                    >
                        Hoe wilt u op de hoogte gehouden worden van uw werkplaatsafspraak?
                        <v-checkbox
                            :input-value="latestNews"
                            @change="updatePathValue('latestNews', $event)"
                            label="Houd mij op de hoogte van het laatste nieuws van Nieuwenhuijse">
                        </v-checkbox>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn
                            :disabled="clicked.includes(1)"
                            class="text-uppercase white--text font-weight-bold rounded-lg mb-2"
                            color="#00b900"
                            width="100%"
                            height="50"
                            @click="planDate()">Plan afspraak &gt;&gt;
                        </v-btn>
                        <v-btn
                            color="secondary"
                            type="button"
                            width="100%"
                            height="50"
                            class="rounded-lg darkgrey--text"
                            @click="previousPage()"
                        >
                            Ga terug
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- Dialogs -->

        <v-dialog
            v-model="contactDialog"
            persistent

            max-width="500"
        >
            <v-card>


                <v-toolbar
                    dark
                    color="primary"
                >
                    <v-toolbar-title>Contactgegevens</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        dark
                        @click="contactDialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-subtitle class="mt-4">
                    U kunt uw contactgegevens in de onderstaande velden invullen.
                </v-card-subtitle>

                <v-card-text>
                    <v-form ref="contactForm">
                        <ContactForm></ContactForm>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="darken-1"
                        text
                        @click.stop="closeContactDialog"
                    >
                        Opslaan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dateDialog"
            persistent
            max-width="500"
        >
            <v-card>
                <v-card-title>
                    <h6 class="text-uppercase darkblue--text">Datum &amp; tijd</h6>
                </v-card-title>

                <v-card-text>
                    <DateForm></DateForm>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="darken-1"
                        text
                        @click.stop="dateDialog = false"
                    >
                        Opslaan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="transportDialog"
            persistent
            max-width="500"
        >
            <v-card>
                <v-card-title>
                    <h6 class="text-uppercase darkblue--text">Vervoer</h6>
                </v-card-title>

                <v-card-text>
                    <TransportForm :dialog-title="true"></TransportForm>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="darken-1"
                        text
                        @click.stop="transportDialog = false"
                    >
                        Opslaan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import ContactForm from "@/components/forms/Contact";
import DateForm from "@/components/forms/Date";
import SidebarMaintenance from "@/components/maintenance/sidebar/Maintenance";
import SidebarYourCar from "@/components/maintenance/sidebar/YourCar";
import TitleText from "@/components/general/TitelText";
import TransportForm from "@/components/forms/Transport";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    "name": "Summary",
    "components": {
        TransportForm,
        ContactForm,
        DateForm,
        SidebarYourCar,
        SidebarMaintenance,
        TitleText
    },
    "computed": {
        appointmentFields () {
            return this.$store.state.car.Appointment.Fields;
        },
        appointmentEmailAddress () {
            return this.$store.state.car.Appointment.Fields.Email;
        },
        maintenanceTime () {
            return this.$store.state.car.maintenanceTime;
        },
        maintenanceDate () {
            return moment(this.$store.state.car.maintenanceDate).locale("nl").format("DD MMMM YYYY");
        },
        currentEstablishmentId () {
            return this.$store.state.car.currentEstablishmentId;
        },
        currentEstablishmentName () {
            return this.$store.state.car.Appointment.Establishment.Name.replace(
                "VOLVO",
                ""
            ).replace(
                "BV",
                ""
            ).replace(
                "B.V.",
                ""
            );
        },
        transport () {
            return this.$store.state.car.currentTransportInfo;
        },
        "remarks": {
            get () {
                return this.$store.state.car.Appointment.AppointmentInfo.Remarks;
            },
            set (value) {
                this.$store.dispatch(
                    "car/updatePathValue",
                    {
                        "object": "Appointment.AppointmentInfo.Remarks",
                        value
                    }
                );
            }
        },
        invoiceMail () {
            return this.$store.state.car.invoiceMail;
        },
        latestNews () {
            return this.$store.state.car.latestNews;
        },
        ...mapGetters({
            "chosenProducts": "car/chosenProducts",
            "currentEstablishment": "establishmentInfo/currentEstablishment"
        })
    },
    data () {
        return {
            "clicked": [],
            "contactDialog": false,
            "dateDialog": false,
            "transportDialog": false,
            "darkblue": this.$vuetify.theme.themes.light.darkblue
        };
    },
    "methods": {
        previousPage () {
            this.$router.go(-1);
        },
        planDate () {
            this.clicked.push(1);
            this.$store.dispatch("car/confirmAppointment")
                .then((response) => {
                    if (response.data.Response === "OK") {
                        const establishment = this.currentEstablishment(this.currentEstablishmentId);
                        if (typeof (establishment) !== "undefined" && typeof (establishment.ThankYouPageUrl) !== "undefined") {
                            window.location = establishment.ThankYouPageUrl;
                        } else {
                            this.$router.push({"name": "Thanks"});
                        }
                    } else {
                        // eslint-disable-next-line no-console
                        console.error(response);
                    }
                });
        },
        updatePathValue (object, value) {
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    object,
                    value
                }
            );
        },
        closeContactDialog () {
            if (!this.$refs.contactForm.validate()) {
                return;
            }
            this.contactDialog = false;
        }
    }
};
</script>

<style scoped>
.v-card__subtitle {
    padding: 10px;
}
</style>
