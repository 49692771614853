<template>
    <div v-if="chosenProducts.length > 0">
        <v-sheet outlined color="darkblue" class="mb-5">
            <v-card tile>
                <v-card-subtitle class="text-uppercase darkblue white--text">
                    <v-icon color="white">mdi-wrench</v-icon>
                    Werkzaamheden
                    <button v-if="this.toggleShown" class="float-right" @click="toggleCard()">
                        <v-icon color="white" v-show="!cardShown">mdi-chevron-down</v-icon>
                        <v-icon color="white" v-show="cardShown">mdi-chevron-up</v-icon>
                    </button>
                </v-card-subtitle>

                <v-card-text class="mt-3 dark--text" v-show="cardShown">
                    <v-row dense v-for="product in chosenProducts" :key="product.id">
                        <v-col cols="98" class="text-truncate" v-html="product.label">www</v-col>
                        <v-col cols="3" align="right"
                               v-if="product.price" v-html="formatPrice(product.price)"></v-col>
                        <v-col cols="3" align="right"
                               v-else-if="product.code.toLowerCase() === 'gratis'" v-html="`Kosteloos`"></v-col>
                        <v-col cols="3" align="right"
                              v-else>-</v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <v-row dense v-if="this.totalProductPrice">
                        <v-col cols="8">Totaal:
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-width="200"
                                offset-x
                                light
                                content-class="elevation-0">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="17"
                                            class="ml-1"
                                            color="darkblue"
                                            v-bind="attrs"
                                            v-on="on">
                                        mdi-information
                                    </v-icon>
                                </template>
                                <v-card dark flat class="ma-2">
                                    <v-card-text>
                                        Dit bedrag is een indicatie. Onze service adviseur stuurt
                                        u een proforma per mail met alle kosten.
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </v-col>
                        <v-col cols="4" align="right">
                            {{ formatPrice(this.totalProductPrice) }}<sup v-if="this.showPriceSubText">1</sup>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-sheet>
    </div>
</template>

<script>

import SidebarCard from "@/components/abstract/SidebarCard";
import {mapGetters} from "vuex";

export default {
    "extends": SidebarCard,
    "name": "SidebarMaintenance",
    "props": ["showPriceSubText"],
    "methods": {
        changeChosenProduct (id, event) {
            this.$store.dispatch(
                "car/changeChosenProduct",
                {
                    id,
                    event
                }
            );
        },
        formatPrice (price) {
            return new Intl.NumberFormat(
                "nl-NL",
                {
                    "style": "currency",
                    "currency": "EUR"
                }
            ).format(price);
        }
    },
    "computed": {
        ...mapGetters({
            "totalProductPrice": "car/calculateTotalProductPrice",
            "chosenProducts": "car/chosenProducts"
        })
    }
};
</script>

<style scoped>
.v-card__subtitle {
    padding: 10px;
}
</style>
