<template>
    <v-sheet
        color="grey lighten-2"
        outlined
        width="100%"
        :height="height"
        class="d-flex justify-center"
    >
        <picture-input
            @change="updateImage(index, $event)"
            height="100"
            width="400"
            margin="0"
            accept="image/jpg,image/jpeg,image/png"
            :crop="false"
            hide-change-button
            :prefill="damageImage(index)"
            :custom-strings="{drag: '+'}">
        </picture-input>
    </v-sheet>
</template>

<script>

import PictureInput from "vue-picture-input";

export default {
    "name": "UploadSheet",
    "components": {PictureInput},
    "props": {
        "index": {
            "type": String
        },
        "height": {
            "type": String,
            default () {
                return "200";
            }
        }
    },
    "methods": {
        damageImage (index) {
            return this.$store.state.car.Appointment.damageImages[index];
        },
        updateImage (index, image) {
            this.$store.dispatch(
                "car/addDamageImage",
                {
                    index,
                    image
                }
            );
        }
    }
};
</script>

<style>

.preview-container {
    max-width: 100% !important;
    width: 100% !important;
    height: 100px !important;
}

.picture-inner {
    border: 0 !important;
}

</style>
