<script>

export default {
    "name": "SidebarCard",
    "props": ["shown", "showToggle"],
    data () {
        let defaultShown = true;
        if (typeof (this.shown) !== "undefined") {
            defaultShown = this.shown;
        }
        let defaultShownToggle = true;
        if (typeof (this.showToggle) !== "undefined") {
            defaultShownToggle = this.showToggle;
        }
        return {
            "cardShown": defaultShown,
            "toggleShown": defaultShownToggle
        };
    },
    "methods": {
        toggleCard () {
            this.cardShown = !this.cardShown;
        }
    }
};
</script>
