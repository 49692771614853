<template>
    <v-row v-if="image">
        <v-col>
            <v-row>
                <v-col>
                    <v-img :src="image"
                           v-on:error="setMechanicImageToDefault"></v-img>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="center">
                    <sub>Afbeelding ter illustratie</sub>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    "name": "CarImage",
    data () {
        return {
            "image": "",
            "counter": 0
        };
    },
    created () {
        this.image = this.carImage;
    },
    "computed": {
        ...mapGetters({
            "carImage": "car/currentCarImage"
        }),
        currentCarMake () {
            return this.$store.state.car.currentCarInfo.make;
        }
    },
    "methods": {
        getDefaultImageByMake (make) {
            if (!make) {
                return "https://assets.nieuwenhuijse.nl/cars/volvo/default.png";
            }
            return `https://assets.nieuwenhuijse.nl/cars/${make.toLowerCase()}/default.png`;
        },
        setMechanicImageToDefault () {
            this.counter += 1;
            let getMakeImage = this.currentCarMake;
            if (this.counter === 2) {
                getMakeImage = false;
            }
            this.image = this.getDefaultImageByMake(getMakeImage);
        }
    }
};
</script>
