import axios from "axios";

export default {
    get (url, config = {}) {
        return axios
            .get(
                process.env.VUE_APP_API_URL + url,
                config
            )
            .then((response) => response)
            .catch((error) => {
                throw error;
            });
    },
    post (url, data, config) {
        const apiURL = process.env.VUE_APP_API_URL + url;
        return axios({
            "method": "POST",
            "headers": config,
            data,
            "url": apiURL
        });
    },
    put (url, data, config) {
        const apiURL = process.env.VUE_APP_API_URL + url;
        return axios({
            "method": "PUT",
            "headers": config,
            data,
            "url": apiURL
        });
    },
    delete (url, data, config = {}) {
        const apiURL = process.env.VUE_APP_API_URL + url;
        return axios({
            "method": "DELETE",
            "headers": config,
            data,
            "url": apiURL
        });
    }
};
