<template>
    <div class="page">

        <v-row>
            <v-col cols="10" offset="1">
                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="ACTIVITIES"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col sm="7" xs="12" cols="12">
                        <v-row>
                            <v-col>
                                <TitleText
                                    title="Verzekerd"
                                    text="Bent u verzekerd tegen ruitschade?"
                                ></TitleText>

                                <v-divider></v-divider>

                                <v-radio-group
                                    @change="updatePathValue('currentDamageRecovery.insurance', $event)"
                                    :value="damageRecovery.insurance"
                                >
                                    <v-radio
                                        label="Ja, ik ben verzekerd tegen ruitschade"
                                        :value="true"

                                    ></v-radio>
                                    <v-radio
                                        label="Nee, ik ben niet verzekerd tegen ruitschade"
                                        :value="false"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <TitleText
                                    title="Hoe groot is de ruitschade"
                                    text="Is het sterretje kleiner dan een twee euro muntstuk?"
                                ></TitleText>
                                <v-divider></v-divider>

                                <v-radio-group
                                    @change="changeWindowJob($event)"
                                    :value="damageRecovery.bigDamage"
                                >
                                    <v-radio
                                        label="Ja, het sterretje is kleiner dan een twee euro muntstuk"
                                        :value="true"
                                    ></v-radio>
                                    <v-radio
                                        label="Nee, ik heb een barst in mijn voorruit."
                                        :value="false"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>

                            <v-col align="right">
                                <v-btn class="rounded-lg text-uppercase"
                                       color="primary"
                                       type="button"
                                       @click="nextPage()">
                                    Ga verder
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: true, showToggle: false}"
                        :maintenance="{show: true, toggle: true, showToggle: false}"
                        :location="{show: false}"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>

    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Sidebar from "@/components/maintenance/Sidebar";
import TitleText from "@/components/general/TitelText";
import {mapGetters} from "vuex";

export default {
    "name": "DamageRecovery",
    "components": {
        BreadCrumbs,
        TitleText,
        Sidebar
    },
    "computed": {
        ...mapGetters({
            "productByCode": "car/getProductByCode"
        }),
        damageRecovery () {
            return this.$store.state.car.currentDamageRecovery;
        }
    },
    created () {
        const windowStar = this.productByCode("voorruitster");
        this.$store.dispatch(
            "car/changeChosenProduct",
            {
                "id": windowStar.id,
                "event": true
            }
        );
    },
    "methods": {
        changeWindowJob (event) {
            this.updatePathValue(
                "currentDamageRecovery.bigDamage",
                event
            );

            const windowStar = this.productByCode("voorruitster");
            const windowReplacement = this.productByCode("voorruitvervanging");
            this.$store.dispatch(
                "car/changeChosenProduct",
                {
                    "id": windowStar.id,
                    event
                }
            );
            this.$store.dispatch(
                "car/changeChosenProduct",
                {
                    "id": windowReplacement.id,
                    "event": !event
                }
            );
        },
        updatePathValue (object, value) {
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    object,
                    value
                }
            );
        },
        previousPage () {
            this.$router.push({"name": "OptionWindowDamageRecovery"});
        },
        nextPage () {
            this.$router.push({"name": "PhotosDamageRecovery"});
        }
    }
};
</script>
