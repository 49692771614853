<template>
  <v-row>
    <v-col>
      <h6 class="text-uppercase dark--text" v-html="title"></h6>
      <p class="dark--text" v-if="text && text !== 'null'" v-html="text"></p>
    </v-col>
  </v-row>
</template>

<script>

export default {
    "name": "TitleText",
    "components": {},
    "props": ["title", "text"]
};
</script>
