<template>
    <div class="page">
        <v-row>
            <v-col cols="10" offset="1">
                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="ACTIVITIES"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col md="7" xs="12">
                        <v-row>
                            <v-col>
                                <strong class="text-uppercase darkblue--text">{{ questions.checklist.label }}</strong>
                                <p v-if="questions.checklist.subtext">{{ questions.checklist.subtext }}</p>
                            </v-col>
                        </v-row>

                        <v-row class="mb-6 mt-0">
                            <v-btn-toggle v-model="CheckList" class="flex-fill">
                                <v-col>
                                    <v-btn
                                        block
                                        class="checklist-button text-uppercase white--text
                                        font-weight-bold rounded-lg"
                                        color="darkblue"
                                        value="Ja"
                                        height="50">Ja
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        block
                                        class="checklist-button text-uppercase font-weight-bold rounded-lg flex-fill"
                                        color="orange"
                                        value="Nee"
                                        height="50">Nee
                                    </v-btn>
                                </v-col>
                            </v-btn-toggle>
                        </v-row>
                        <v-row>
                            <v-col>
                                <strong class="text-uppercase darkblue--text">
                                    {{ questions.replacingparts.label }}
                                </strong>
                                <p v-if="questions.replacingparts.subtext">{{ questions.replacingparts.subtext }}</p>
                            </v-col>
                        </v-row>

                        <v-row class="mb-6 mt-0">
                            <v-btn-toggle v-model="KeepReplacedParts" class="flex-fill">
                                <v-col>
                                    <v-btn
                                        block
                                        class="checklist-button text-uppercase white--text
                                        font-weight-bold rounded-lg"
                                        color="darkblue"
                                        value="Ja"
                                        height="50">Ja
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        block
                                        class="checklist-button text-uppercase font-weight-bold rounded-lg flex-fill"
                                        color="orange"
                                        value="Nee"
                                        height="50">Nee
                                    </v-btn>
                                </v-col>
                            </v-btn-toggle>
                        </v-row>
                        <v-row v-if="showMechanicalWash">
                            <v-col>
                                <strong class="text-uppercase darkblue--text">{{ questions.wash.label }}</strong>
                                <p v-if="questions.wash.subtext">{{ questions.wash.subtext }}</p>
                            </v-col>
                        </v-row>

                        <v-row v-if="showMechanicalWash" class="mb-6 mt-0">
                            <v-btn-toggle v-model="MechanicalWash" class="flex-fill">
                                <v-col>
                                    <v-btn
                                        block
                                        class="checklist-button text-uppercase white--text
                                        font-weight-bold rounded-lg"
                                        color="darkblue"
                                        value="Ja"
                                        height="50">Ja
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        block
                                        class="checklist-button text-uppercase font-weight-bold rounded-lg flex-fill"
                                        color="orange"
                                        value="Nee"
                                        height="50">Nee
                                    </v-btn>
                                </v-col>
                            </v-btn-toggle>
                        </v-row>

                        <v-row>
                            <v-col>
                                <sub v-if="validationMessage" class="red--text">
                                    Alle vragen zijn verplicht
                                </sub>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>

                            <v-col cols="6" align="right">
                                <v-btn class="rounded-lg text-uppercase"
                                       color="primary"
                                       type="button"
                                       @click="nextPage()">
                                    Ga verder
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: true, showToggle: false}"
                        :maintenance="{show: true, toggle: true, showToggle: false}"
                        :location="{show: false}"
                        class="hidden-xs-only"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Sidebar from "@/components/maintenance/Sidebar";
import {mapGetters} from "vuex";

export default {
    "name": "CheckList",
    "components": {
        BreadCrumbs,
        Sidebar
    },
    data () {
        return {
            "validationMessage": false,
            "questions": {
                "checklist": {
                    "name": "checklist",
                    "label": "Wilt u de checklist ontvangen?",
                    "subtext": "Dit is een overzicht van alle uitgevoerde werkzaamheden.",
                    "data": this.CheckList
                },
                "replacingparts": {
                    "name": "vervangenonderdelen",
                    "label": "Wilt u dat wij de vervangen onderdelen bewaren?",
                    "data": this.KeepReplacedParts
                },
                "wash": {
                    "name": "wassen",
                    "label": "Mogen wij uw auto machinaal wassen?",
                    "data": this.MechanicalWash
                }
            },
            "showMechanicalWash": this.$store.state.car.mechanicalWash
        };
    },
    "computed": {
        ...mapGetters({
            "nextActionProduct": "car/getNextActionProduct"
        }),
        "CheckList": {
            get () {
                return this.$store.state.car.Appointment.CheckList.CheckList;
            },
            set (value) {
                this.$store.dispatch(
                    "car/updatePathValue",
                    {
                        "object": "Appointment.CheckList.CheckList",
                        value
                    }
                );
            }
        },
        "KeepReplacedParts": {
            get () {
                return this.$store.state.car.Appointment.CheckList.KeepReplacedParts;
            },
            set (value) {
                this.$store.dispatch(
                    "car/updatePathValue",
                    {
                        "object": "Appointment.CheckList.KeepReplacedParts",
                        value
                    }
                );
            }
        },
        "MechanicalWash": {
            get () {
                return this.$store.state.car.Appointment.CheckList.MechanicalWash;
            },
            set (value) {
                this.$store.dispatch(
                    "car/updatePathValue",
                    {
                        "object": "Appointment.CheckList.MechanicalWash",
                        value
                    }
                );
            }
        }
    },
    "methods": {
        nextButtonVisible () {
            return (this.CheckList !== "" && this.KeepReplacedParts !== "");
        },
        previousPage () {
            if (typeof (this.nextActionProduct) !== "undefined") {
                this.$router.push({"name": "ActionPage"});
            } else {
                this.$router.push({"name": "OptionWindowDamageRecovery"});
            }
        },
        nextPage () {
            if (!this.nextButtonVisible()) {
                this.validationMessage = true;
            } else {
                this.validationMessage = false;
                this.$router.push({"name": "Establishments"});
            }
        }
    }
};
</script>

<style scoped>
.theme--light.v-btn.checklist-button {
    opacity: 0.2 !important;
    border: 5px solid;
}

.theme--light.checklist-button.v-btn--active,
.theme--light.checklist-button.v-btn--active:hover {
    opacity: 1 !important;
    border: 0;
}
.theme--light.v-btn--active:before,
.theme--light.v-btn--active:hover:before {
    opacity: 0;
}
</style>
