<template>
    <div class="page">

        <v-row>
            <v-col cols="10" offset="1">
                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="ACTIVITIES"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between" v-if="nextActionProduct">
                    <v-col md="7" xs="12">
                        <v-row>
                            <v-col>
                                <TitleText :title="`Actie: ${nextActionProduct.label}`"
                                           :text="`${nextActionProduct.subtext}`"></TitleText>

                                <strong class="text-uppercase">
                                    Actieprijs: {{ formatPrice(nextActionProduct.price) }}
                                </strong>

                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn
                                    class="text-uppercase white--text font-weight-bold rounded-lg"
                                    color="darkblue"
                                    width="100%"
                                    height="50"
                                    @click="nextPage(true, nextActionProduct.id)">Ja
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn
                                    class="text-uppercase font-weight-bold rounded-lg"
                                    color="orange"
                                    width="100%"
                                    height="50"
                                    @click="nextPage(false, nextActionProduct.id)">Nee
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: true, showToggle: false}"
                        :maintenance="{show: true, toggle: true, showToggle: false}"
                        :location="{show: false}"
                        class="hidden-xs-only"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>

    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Sidebar from "@/components/maintenance/Sidebar";
import TitleText from "@/components/general/TitelText";
import {mapGetters} from "vuex";

export default {
    "name": "ActionPage",
    "components": {
        BreadCrumbs,
        Sidebar,
        TitleText
    },
    "computed": {
        ...mapGetters({
            "nextActionProduct": "car/getNextActionProduct"
        })
    },
    "methods": {
        previousPage () {
            this.$router.push({"name": "OptionWindowDamageRecovery"});
        },
        nextPage (getAction, id) {
            this.$store.dispatch(
                "car/changeActionProduct",
                {
                    id,
                    "event": getAction
                }
            );

            if (typeof (this.nextActionProduct) !== "undefined") {
                this.$router.push({
                    "name": "ActionPage"
                    // eslint-disable-next-line no-empty-function
                }).catch(() => {});
            } else {
                this.$router.push({
                    "name": "CheckList"
                    // eslint-disable-next-line no-empty-function
                }).catch(() => {});
            }
        },
        formatPrice (price) {
            return new Intl.NumberFormat(
                "nl-NL",
                {
                    "style": "currency",
                    "currency": "EUR"
                }
            ).format(price);
        }
    }
};
</script>
