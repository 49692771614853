<template>
    <div class="home">

        <v-row justify="space-between">
            <v-col cols="10">
                <h6 class="text-uppercase dark--text">Werkplaatsplanner</h6>
                <span>Via onze planner maakt u eenvoudig een werkplaatsafspraak.
                    Kies een vestiging en een moment dat u het beste uitkomt.</span>
            </v-col>
        </v-row>
        <v-form ref="licensePlateForm">
            <v-row justify="space-between">
                <v-col xs="10" md="5">

                    <TitleText title="Bent u klant bij ons?"
                               text="Als u al klant bij ons bent kunnen wij alvast een gedeelte voor u invullen.">
                    </TitleText>

                    <v-row class="mt-0">
                        <v-col>
                            <v-radio-group
                                row
                                required
                                :rules="[rules.required]"
                                v-model="existingCustomer"
                                class="mt-0"
                            >
                                <v-card tile elevation="0" outlined class="col mr-2">
                                    <v-radio
                                        label="Ja"
                                        :value="true"
                                    ></v-radio>
                                </v-card>
                                <v-card tile elevation="0" outlined class="col">
                                    <v-radio
                                        label="Nee"
                                        :value="false"
                                    ></v-radio>
                                </v-card>
                            </v-radio-group>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col>
                            <v-text-field
                                :name="Math.random()"
                                :rules="[rules.required]"
                                v-model="lisencePlate"
                                class="plate"
                                height="60"
                                flat
                                solo
                                required
                                autocomplete="new-password"
                                @keydown.enter="nextPage"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col class="pt-0">
                            <v-text-field
                                name="currentKm"
                                label="Kilometerstand"
                                :rules="[rules.required]"
                                v-model="currentKm"
                                maxlength="6"
                                @keypress="isNumber($event)"
                                @keydown.enter="nextPage"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col>
                            <v-btn
                                color="primary"
                                width="100%"
                                height="41px"
                                class="rounded-lg"
                                @click="nextPage()"
                            >
                                Ga verder
                            </v-btn>
                        </v-col>
                    </v-row>

                    <WhatsApp></WhatsApp>
                </v-col>

                <v-divider vertical class="d-none d-sm-flex"></v-divider>

                <v-col xs="10" md="5">
                    <v-row>
                        <v-col>
                            <h6 class="text-uppercase dark--text">{{this.label("insurances")}}</h6>
                            <p v-if="insurancesWithoutLists" v-html="insurancesWithoutLists"></p>
                            <v-list subheader v-if="insurances.length > 0">
                                <v-list-item
                                    v-for="item in insurances"
                                    :key="item.outerText"
                                    class="pl-0"
                                >
                                    <v-list-item-avatar>
                                        <v-icon large color="darkblue">mdi-check</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content class="dark--text" v-html="item.outerText">
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>

                    <Reviews></Reviews>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>

import Reviews from "@/components/general/Reviews";
import TitleText from "@/components/general/TitelText";
import WhatsApp from "@/components/general/WhatsApp";
import {mapGetters} from "vuex";
import moment from "moment";
import nl from "./../../static/img/nl.jpeg";

export default {
    "name": "Home",
    "components": {
        Reviews,
        TitleText,
        WhatsApp
    },
    data () {
        return {
            nl,
            "rules": {
                "required": (value) => value !== null || "Verplicht veld."
            },
            "securities": []
        };
    },
    async created () {
        await this.$store.dispatch("establishmentInfo/getEstablishmentInfo");
        if (this.$route.query.kenteken) {
            this.lisencePlate = this.$route.query.kenteken;
            await this.$store.dispatch(
                "car/updatePathValue",
                {
                    "object": "Appointment.Fields.RegistrationNumber",
                    "value": this.$route.query.kenteken
                }
            );
            await this.$store.dispatch(
                "car/updatePathValue",
                {
                    "object": "existingCustomer",
                    "value": true
                }
            );
        }
        if (this.$route.query.kmstand) {
            this.currentKm = this.$route.query.kmstand;
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    "object": ["Appointment.Fields.Mileage"],
                    "value": this.$route.query.kmstand
                }
            );
        }
        if (this.$route.query.email) {
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    "object": ["Appointment.Fields.Email"],
                    "value": this.$route.query.email
                }
            );
        }
    },
    "computed": {
        ...mapGetters({
            "text": "cms/getTextByName",
            "label": "cms/getLabelByName"
        }),
        insurances () {
            if (this.text("insurances") === "") {
                return [];
            }
            const doc = document.createElement("div");
            doc.insertAdjacentHTML(
                "afterbegin",
                this.text("insurances")
            );
            const listItems = doc.getElementsByTagName("li");
            if (listItems.length > 0) {
                return listItems;
            }
            return [];
        },
        insurancesWithoutLists () {
            if (this.insurances.length > 0) {
                const doc = document.createElement("div");
                doc.insertAdjacentHTML(
                    "afterbegin",
                    this.text("insurances")
                );
                doc.querySelector("ul").remove();

                return doc.innerHTML;
            }
            return "";
        },
        rdwData () {
            return this.$store.state.car.currentRdwData;
        },
        "existingCustomer": {
            get () {
                return this.$store.state.car.existingCustomer;
            },
            set (value) {
                this.$store.dispatch(
                    "car/updatePathValue",
                    {
                        "object": "existingCustomer",
                        value
                    }
                );
            }
        },
        "currentKm": {
            get () {
                return this.$store.state.car.Appointment.Fields.Mileage;
            },
            set (value) {
                this.$store.dispatch(
                    "car/updatePathValue",
                    {
                        "object": ["Appointment.Fields.Mileage"],
                        value
                    }
                );
            }
        },
        "lisencePlate": {
            get () {
                return this.$store.state.car.Appointment.Fields.RegistrationNumber;
            },
            set (value) {
                const formattedLicensePlate = value.trim().replaceAll(
                    "-",
                    ""
                ).replaceAll(
                    " ",
                    ""
                );
                this.$store.dispatch(
                    "car/updatePathValue",
                    {
                        "object": "Appointment.Fields.RegistrationNumber",
                        "value": formattedLicensePlate
                    }
                );
            }
        }
    },
    "methods": {
        isNumber (event) {
            const charCode = (event.which) ? event.which : event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                event.preventDefault();
            }
            return true;
        },
        // eslint-disable-next-line max-lines-per-function
        async nextPage () {
            if (!this.$refs.licensePlateForm.validate()) {
                return;
            }
            await this.updateCurrentCarInfo();

            await this.$router.push({"name": "YourCar"});
        },
        // eslint-disable-next-line max-lines-per-function
        async updateCurrentCarInfo () {
            const rdwData = await this.getRDWData();
            await this.setCarImage({
                "make": rdwData.merk,
                "modelYear": moment(
                    rdwData.datum_eerste_toelating,
                    "YYYYMMDD"
                ).format("YYYY"),
                "name": rdwData.handelsbenaming
            });
            let currentCarInfo = this.mapRDWData(rdwData);
            // eslint-disable-next-line no-console
            const searchData = await this.searchLicensePlate();
            if (searchData) {
                await this.setCarImage({
                    "make": searchData.car.make,
                    "modelYear": searchData.car.modelYear,
                    "name": searchData.car.name
                });
                // eslint-disable-next-line camelcase
                searchData.recall_codes = rdwData.reference_codes;
                // If licenseplate found, use this data instead of the RDW one
                currentCarInfo = this.mapSLPData(searchData);
            }

            for (const field of currentCarInfo) {
                // eslint-disable-next-line no-console
                // eslint-disable-next-line no-await-in-loop
                await this.$store.dispatch(
                    "car/updatePathValue",
                    {
                        "object": field.object,
                        "value": field.value
                    }
                );
            }
            return currentCarInfo;
        },
        async searchLicensePlate () {
            const licensePlate = this.$store.state.car.Appointment.Fields.RegistrationNumber;
            const searchResponse = await this.$store.dispatch(
                "car/searchLicensePlate",
                licensePlate
            );
            if (!searchResponse.data) {
                return false;
            }
            return searchResponse.data;
        },
        async getRDWData () {
            const licensePlate = this.$store.state.car.Appointment.Fields.RegistrationNumber;
            await this.$store.dispatch(
                "car/getRdwData",
                licensePlate
            );
            return this.rdwData;
        },
        async setCarImage (data) {
            if (!data.make || !data.modelYear || !data.name) {
                return;
            }
            await this.$store.dispatch(
                "car/updatePathValue",
                {
                    "object": "currentCarImage",
                    "value": `https://assets.nieuwenhuijse.nl/cars/${data.make.toLowerCase()}/${data.modelYear}/${data.name.toLowerCase()}.png`
                }
            );
        },
        mapRDWData (data) {
            return [
                {
                    "object": ["currentCarInfo.make", "Appointment.CarInfo.Make", "Appointment.Fields.Make"],
                    "value": data.merk
                }, {
                    "object": ["currentCarInfo.model", "Appointment.CarInfo.Model"],
                    "value": data.handelsbenaming
                }, {
                    "object": ["currentCarInfo.modelYear", "Appointment.CarInfo.ModelYear"],
                    "value": moment(
                        data.datum_eerste_toelating,
                        "YYYYMMDD"
                    ).format("YYYY")
                }, {
                    "object": ["currentCarInfo.fuelType", "Appointment.CarInfo.FuelType"],
                    "value": data.brandstof_omschrijving
                }, {
                    "object": ["currentCarInfo.transmission", "Appointment.CarInfo.Transmission"],
                    "value": data.transmissie
                }, {
                    "object": "currentCarInfo.apkvervaldatum",
                    "value": data.vervaldatum_apk_dt
                }, {
                    "object": "currentCarInfo.licenseFound",
                    "value": false
                }, {
                    "object": "Appointment.CarInfo.recall_codes",
                    "value": data.reference_codes
                }
            ];
        },
        // eslint-disable-next-line max-lines-per-function
        mapSLPData (data) {
            return [
                {
                    "object": ["currentCarInfo.make", "Appointment.CarInfo.Make", "Appointment.Fields.Make"],
                    "value": data.car.make
                }, {
                    "object": ["currentCarInfo.model", "Appointment.CarInfo.Model"],
                    "value": data.car.name.replace(
                        data.car.make,
                        ""
                    )
                }, {
                    "object": ["currentCarInfo.modelId", "Appointment.CarInfo.ModelId"],
                    "value": data.car.modelId
                }, {
                    "object": ["currentCarInfo.modelYear", "Appointment.CarInfo.ModelYear"],
                    "value": data.car.modelYear
                }, {
                    "object": ["currentCarInfo.motorName", "Appointment.CarInfo.MotorName"],
                    "value": data.plate.motornaam
                }, {
                    "object": ["currentCarInfo.fuelType", "Appointment.CarInfo.FuelType"],
                    "value": data.plate.brandstof
                }, {
                    "object": ["currentCarInfo.transmission", "Appointment.CarInfo.Transmission"],
                    "value": data.plate.transmissie
                }, {
                    "object": ["currentCarInfo.establishment"],
                    "value": data.plate.vestiging
                }, {
                    "object": ["currentKlantNr"],
                    "value": data.plate.klantnrmrr
                }, {
                    "object": ["currentCarInfo.laatsteonderhouddatum"],
                    "value": data.plate.laatsteonderhouddatum
                }, {
                    "object": ["currentCarInfo.laatsteonderhoudkm"],
                    "value": data.plate.laatsteonderhoudkm
                }, {
                    "object": ["currentCarInfo.laatsteruitenwisserbladenvoordatum"],
                    "value": data.plate.laatsteruitenwisserbladenvoordatum
                }, {
                    "object": ["currentCarInfo.laatsteremvloeistofdatum"],
                    "value": data.plate.laatsteremvloeistofdatum
                }, {
                    "object": ["currentCarInfo.laatsteaircovuldatum"],
                    "value": data.plate.laatsteaircovuldatum
                }, {
                    "object": "currentCarInfo.licenseFound",
                    "value": true
                }, {
                    "object": "Appointment.CarInfo.recall_codes",
                    "value": data.recall_codes
                }
            ];
        }
    }
};
</script>

<style scoped>

>>> .plate input {
    line-height: 65px;
    background-color: #ffc938 !important;
    border: 0;
    border-radius: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 45px;
    line-height: 100%;
    text-transform: uppercase;
    margin: 0 auto;
    background-image: url(~@/assets/nl.jpeg) !important;
    background-repeat: no-repeat;
    background-size: 31px;
    max-height: none;
    height: 63px;
}

>>> .plate input:-webkit-autofill,
>>> .plate input:-webkit-autofill:hover,
>>> .plate input:-webkit-autofill:focus,
>>> .plate input:-webkit-autofill:active {
    border: unset !important;
    -webkit-text-fill-color: unset !important;
    -webkit-box-shadow: 0 0 0px 1000px #ffc938 inset;
    background-image: url(~@/assets/nl.jpeg) !important;
    transition: unset !important;
}

</style>
