<template>
    <div class="thanks">
        <v-row>
            <v-col>
                <TitleText title="Bedankt voor het vertrouwen"></TitleText>
                <div v-html="text('thankYou')"></div>
            </v-col>
            <v-col>
                <youtube :video-id="getIdFromURL(text('thankYouTube'))"></youtube>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import TitleText from "@/components/general/TitelText";
import {getIdFromURL} from "vue-youtube-embed";
import {mapGetters} from "vuex";

export default {
    "name": "Home",
    "components": {
        TitleText
    },
    "computed": {
        ...mapGetters({
            "text": "cms/getTextByName"
        })
    },
    "methods": {
        getIdFromURL (url) {
            return getIdFromURL(url);
        }
    }
};

</script>
