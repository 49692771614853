import "vuetify/dist/vuetify.min.css";

import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
    "theme": {
        "themes": {
            "light": {
                "primary": "#395448",
                "secondary": "#DFDFDF",
                "darkblue": "#395448",
                "dark": "#000000",
                "darkgrey": "#395448",
                "orange": "#ffffff"
            }
        }
    }
});
