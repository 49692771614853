<template>
    <div class="page">

        <v-row>
            <v-col cols="10" offset="1">
                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="ACTIVITIES"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col md="7" xs="12">
                        <v-row>
                            <v-col>
                                <TitleText title="Ruitschadeherstel"
                                           text="Zit er een sterretje in uw voorruit dat u wilt laten repareren?
                                           In de meeste gevallen dekt uw verzekering deze kosten."></TitleText>

                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn
                                    class="text-uppercase white--text font-weight-bold rounded-0"
                                    color="darkblue"
                                    width="100%"
                                    height="50"
                                    @click="nextPage(true)">Ja
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn
                                    class="text-uppercase font-weight-bold rounded-0"
                                    color="orange"
                                    width="100%"
                                    height="50"
                                    @click="nextPage(false)">Nee
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       height="41px"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: true, showToggle: false}"
                        :maintenance="{show: true, toggle: true, showToggle: false}"
                        :location="{show: false}"
                        class="hidden-xs-only"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>

    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Sidebar from "@/components/maintenance/Sidebar";
import TitleText from "@/components/general/TitelText";
import {mapGetters} from "vuex";

export default {
    "name": "WindowDamageRecovery",
    "components": {
        BreadCrumbs,
        Sidebar,
        TitleText
    },
    "computed": {
        damageRecovery () {
            return this.$store.state.car.currentDamageRecovery;
        },
        ...mapGetters({
            "nextActionProduct": "car/getNextActionProduct",
            "productByCode": "car/getProductByCode"
        })
    },
    "methods": {
        previousPage () {
            this.$router.push({"name": "MaintenanceAdviceOptions"});
        },
        nextPage (windowDamageRecovery) {
            const product = this.productByCode("voorruitster");
            if (product) {
                this.$store.dispatch(
                    "car/changeChosenProduct",
                    {
                        "id": product.id,
                        "event": windowDamageRecovery
                    }
                );
            }
            if (typeof (this.nextActionProduct) !== "undefined") {
                this.$router.push({"name": "ActionPage"});
            } else if (windowDamageRecovery) {
                this.$router.push({"name": "WindowDamageRecovery"});
            } else {
                this.$router.push({"name": "Establishments"});
            }
        }
    }
};
</script>
