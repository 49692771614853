import api from "../../helpers/apiHandler";

const getDefaultState = () => ({
    "reviews": []
});

const state = getDefaultState();

const mutations = {
    SET_REVIEWS (state, reviews) {
        state.reviews = reviews;
    },
    apiFailure (newState, value) {
        return value;
    }
};

const getters = {
    "reviews": (state) => state.reviews
};

const actions = {
    async getReviews ({commit}) {
        await api.get(
            "v1/review",
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "SET_REVIEWS",
                    response.data
                );
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
