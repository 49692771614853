<template>
    <div class="page">

        <v-row>
            <v-col sm="10" xs="12" cols="12" sm-offset="1">
                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="ACTIVITIES"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col sm="7" xs="12" cols="12">
                        <v-row>
                            <v-col>

                                <TitleText
                                    title="Foto's van uw schade"
                                    text="Druk op het plusje om eventueel foto's
                                    van de ruitschade toe te voegen. Dit helpt
                                    ons bij het inschatten van de werkzaamheden
                                    om u werkplaatsbezoek zo kort mogelijk te houden."
                                ></TitleText>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>

                                <v-row>
                                    <v-col>
                                        <UploadSheet height="100" index="0"></UploadSheet>
                                    </v-col>
                                    <v-col>
                                        <UploadSheet height="100" index="1"></UploadSheet>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>

                                <TitleText
                                    title="Beschrijving van uw schade"
                                    text="Geef een beschrijving van de schade
                                    aan uw auto. Dit helpt ons bij het inschatten
                                    van de werkzaamheden om u werkplaatsbezoek
                                    zo kort mogelijk te houden."
                                ></TitleText>

                                <v-divider></v-divider>

                                <v-textarea
                                    label="Geef een beschrijving van uw schade..."
                                    @change="updatePathValue('currentDamageRecovery.description', $event)"
                                    :value="damageRecovery.description"></v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>

                            <v-col align="right">
                                <v-btn class="rounded-lg text-uppercase"
                                       color="primary"
                                       type="button"
                                       @click="nextPage()">
                                    Ga verder
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: true, showToggle: false}"
                        :maintenance="{show: true, toggle: true, showToggle: false}"
                        :location="{show: false}"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>

    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Sidebar from "@/components/maintenance/Sidebar";
import TitleText from "@/components/general/TitelText";
import UploadSheet from "@/components/forms/UploadSheet";

export default {
    "name": "PhotosDamageRecovery",
    "components": {
        BreadCrumbs,
        UploadSheet,
        TitleText,
        Sidebar
    },
    "computed": {
        damageRecovery () {
            return this.$store.state.car.currentDamageRecovery;
        }
    },
    "methods": {
        updatePathValue (object, value) {
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    object,
                    value
                }
            );
        },
        previousPage () {
            this.$router.push({"name": "WindowDamageRecovery"});
        },
        nextPage () {
            this.$router.push({"name": "Establishments"});
        }
    }
};
</script>
