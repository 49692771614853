<template>
    <v-col sm="4" xs="12" cols="12">
        <SidebarAlerts></SidebarAlerts>

        <v-row>
            <v-col>
                <SidebarYourCar
                    v-if="this.car.show"
                    :shown="this.car.toggle"
                    :show-toggle="this.car.showToggle"></SidebarYourCar>

                <SidebarMaintenance
                    v-if="this.maintenance.show"
                    :shown="this.maintenance.toggle"
                    :show-toggle="this.maintenance.showToggle"></SidebarMaintenance>

                <SidebarLocationTime
                    v-if="this.location.show"
                    :shown="this.location.toggle"
                    :show-toggle="this.location.showToggle"></SidebarLocationTime>

                <SidebarWait
                    v-if="this.wait.show"
                    :shown="this.wait.toggle"
                    :show-toggle="this.wait.showToggle"></SidebarWait>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>

import SidebarAlerts from "@/components/maintenance/sidebar/Alerts";
import SidebarLocationTime from "@/components/maintenance/sidebar/LocationTime";
import SidebarMaintenance from "@/components/maintenance/sidebar/Maintenance";
import SidebarWait from "@/components/maintenance/sidebar/Wait";
import SidebarYourCar from "@/components/maintenance/sidebar/YourCar";


export default {
    "props": {
        "car": {
            "type": Object,
            default () {
                return {
                    "show": false,
                    "shown": false,
                    "show-toggle": false
                };
            }
        },
        "maintenance": {
            "type": Object,
            default () {
                return {
                    "show": false,
                    "shown": false,
                    "show-toggle": false
                };
            }
        },
        "location": {
            "type": Object,
            default () {
                return {
                    "show": false,
                    "shown": false,
                    "show-toggle": false
                };
            }
        },
        "wait": {
            "type": Object,
            default () {
                return {
                    "show": false,
                    "shown": false,
                    "show-toggle": false
                };
            }
        }
    },
    "name": "Sidebar",
    "components": {
        SidebarAlerts,
        SidebarLocationTime,
        SidebarMaintenance,
        SidebarWait,
        SidebarYourCar
    }
};

</script>
