<template>
    <div class="page">

        <v-row>
            <v-col cols="10" offset="1">
                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="ACTIVITIES"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col cols="7">
                        <v-row>
                            <v-col>

                                <TitleText
                                    title="Is uw auto rijdbaar?"
                                    text="Is uw auto nog rijdbaar en kunt u bij een van onze vestigingen komen?"
                                ></TitleText>
                                <v-divider></v-divider>

                                <v-radio-group
                                    @change="updatePathValue('currentDamageRecovery.carDrivable', $event)"
                                    :value="damageRecovery.carDrivable"
                                >
                                    <v-radio
                                        label="Ja, mijn auto is nog rijdbaar"
                                        :value="true"

                                    ></v-radio>
                                    <v-radio
                                        label="Nee, mijn auto is niet meer rijdbaar"
                                        :value="false"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col>

                                <TitleText
                                    title="Schadeformulier"
                                    text="Druk op het plusje om eventueel uw schadeformulier toe te voegen.
                                    Deze kunt u ook meenemen naar uw afspraak."
                                ></TitleText>
                                <v-divider></v-divider>

                                <v-radio-group
                                    @change="updatePathValue('currentDamageRecovery.damageForm', $event)"
                                    :value="damageRecovery.damageForm"
                                >
                                    <v-radio
                                        label="Ja, ik heb een schadeformulier"
                                        :value="true"

                                    ></v-radio>
                                    <v-radio
                                        label="Nee, ik heb geen schadeformulier"
                                        :value="false"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <UploadSheet></UploadSheet>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>

                            <v-col align="right">
                                <v-btn class="rounded-lg text-uppercase"
                                       color="primary"
                                       type="button"
                                       @click="nextPage()">
                                    Ga verder
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: true, showToggle: false}"
                        :maintenance="{show: true, toggle: true, showToggle: false}"
                        :location="{show: false}"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>

    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Sidebar from "@/components/maintenance/Sidebar";
import TitleText from "@/components/general/TitelText";
import UploadSheet from "@/components/forms/UploadSheet";

export default {
    "name": "DamageRecovery",
    "components": {
        BreadCrumbs,
        TitleText,
        Sidebar,
        UploadSheet
    },
    "computed": {
        damageRecovery () {
            return this.$store.state.car.currentDamageRecovery;
        }
    },
    "methods": {
        updatePathValue (object, value) {
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    object,
                    value
                }
            );
        },
        previousPage () {
            this.$router.push({"name": "AppointmentType"});
        },
        nextPage () {
            this.$router.push({"name": "PhotosDamageRecovery"});
        }
    }
};
</script>
