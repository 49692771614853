<template>
    <div class="page">

        <v-row>
            <v-col cols="10" offset="1">
                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="LOCATIONTIME"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col md="7" xs="12">
                        <v-row>
                            <v-col>
                                <TitleText title="Wilt u wachten op de werkzaamheden?"
                                           text="Terwijl u wacht, kunt u gebruik maken van één van onze
                                           flexwerkplekken onder het genot van een kop koffie of thee."></TitleText>
                                <strong>
                                    De verwachte duur van de werkzaamheden is: {{ timeConvert(totalTime) }}
                                </strong>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn
                                    class="text-uppercase white--text font-weight-bold rounded-lg"
                                    color="darkblue"
                                    width="100%"
                                    height="50"
                                    @click="nextPage(true)">Ja
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn
                                    class="text-uppercase font-weight-bold rounded-lg"
                                    color="orange"
                                    width="100%"
                                    height="50"
                                    @click="nextPage(false)">Nee
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       height="41px"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: false, showToggle: true}"
                        :maintenance="{show: true, toggle: false, showToggle: true}"
                        :location="{show: true, showToggle: false}"
                        :wait="{show: true, showToggle: false}"
                        class="hidden-xs-only"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>

    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Sidebar from "@/components/maintenance/Sidebar";
import TitleText from "@/components/general/TitelText";
import {mapGetters} from "vuex";

export default {
    "name": "Wachten",
    "components": {
        BreadCrumbs,
        Sidebar,
        TitleText
    },
    "computed": {
        ...mapGetters({
            "totalTime": "car/totalTimeUnits"
        })
    },
    "methods": {
        // eslint-disable-next-line consistent-return
        timeConvert (n) {
            // eslint-disable-next-line no-param-reassign
            n *= 30;
            const hours = (n / 60);
            const rhours = Math.floor(hours);
            const minutes = (hours - rhours) * 60;
            const rminutes = Math.round(minutes);
            if (rhours === 0 && rminutes > 0) {
                return `${rminutes} minuten`;
            } else if (rhours > 0 && rminutes > 0) {
                return `${rhours} uur en ${rminutes} minuten`;
            } else if (rhours > 0 && rminutes === 0) {
                return `${rhours} uur`;
            }
        },
        updatePathValue (object, value) {
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    object,
                    value
                }
            );
        },
        previousPage () {
            this.$router.push({"name": "Establishments"});
        },
        nextPage (wait) {
            this.updatePathValue(
                "waitForMaintenance",
                Boolean(wait)
            );

            this.updatePathValue(
                "Appointment.CarInfo.waitForMaintenance",
                Boolean(wait)
            );
            this.$router.push({"name": "DateTime"});
        }
    }
};
</script>
