<template>
    <Planner :next-route="false"></Planner>
</template>

<script>

import Planner from "@/components/general/Planner";

export default {
    "name": "DateForm",
    "components": {
        Planner
    },
    data () {
        return {
            "rules": {
                "required": (value) => Boolean(value) || "Verplicht veld.",
                "email": (value) => !value || (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/u).test(value) || "E-mail is niet correct."
            }
        };
    }
};
</script>
