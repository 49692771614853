import api from "../../helpers/apiHandler";

// eslint-disable-next-line max-lines-per-function
const getDefaultState = () => ({
    "Establishments": []
});

const state = getDefaultState();

const mutations = {
    SET_ESTABLISHMENTS (state, data) {
        state.Establishments = data;
    },
    UPDATE_ESTABLISHMENTS (state, data) {
        state.Establishments = data;
    },
    RESET_STATE (state) {
        Object.assign(
            state,
            getDefaultState()
        );
    },
    apiFailure (newState, value) {
        return value;
    }
};

const getters = {
    "currentEstablishment": (state) => (id) => (state.Establishments.find((est) => est.Id === id)),
    "getPoleStarEstablishments": (state) => (state.Establishments.filter((est) => (est.Makes.find((make) => make.Name.toUpperCase() === "POLESTAR")))),
    "getVolvoEstablishments": (state) => (state.Establishments.filter((est) => (est.Makes.find((make) => make.Name.toUpperCase() === "VOLVO")))),
    "getEstablishmentsByMake": (state) => (make) => (state.Establishments.filter((est) => (est.Makes.find((m) => m.Name.toUpperCase() === make.toUpperCase()))))
};

const actions = {
    getEstablishmentInfo ({commit}) {
        return api.get(
            "v1/planit/establishmentinfo",
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                if (typeof (response.data) !== "undefined" && response.data.length > 0) {
                    const establishments = response.data;
                    commit(
                        "SET_ESTABLISHMENTS",
                        establishments
                    );
                }
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    },
    resetState ({commit}) {
        commit("RESET_STATE");
    },
    getFirstAvailablePlanDate ({commit, rootState}) {
        const params =
            {"products": rootState.car.chosenProducts};
        return api.post(
            "v1/planit/firstavailableplandate",
            params,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        )
            .then((response) => {
                if (typeof (response.data) !== "undefined" && response.data.length > 0) {
                    const esta = response.data;
                    commit(
                        "UPDATE_ESTABLISHMENTS",
                        esta
                    );
                }
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
