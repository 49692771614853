<template>
    <div class="page">

        <v-row>
            <v-col cols="10" offset="1">
                <v-row>
                    <v-col>
                        <BreadCrumbs></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col cols="7">
                        <v-row>
                            <v-col>

                                <TitleText title="Datum en tijd"
                                           text="De verwachte werkzaamhedenduur is 2 uur en 30 minuten."></TitleText>

                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <Planner :next-route="nextRouteAfterPlanning"></Planner>

                        <v-row>
                            <v-col>
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>

                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: false, showToggle: true}"
                        :maintenance="{show: true, toggle: false, showToggle: true}"
                        :location="{show: true, showToggle: false}"
                        :wait="{show: true, showToggle: false}"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>

    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Planner from "@/components/general/Planner";
import Sidebar from "@/components/maintenance/Sidebar";
import TitleText from "@/components/general/TitelText";

export default {
    "name": "PlannerDamageRecovery",
    "components": {
        BreadCrumbs,
        Planner,
        Sidebar,
        TitleText
    },
    "computed": {
        damageRecovery () {
            return this.$store.state.car.currentDamageRecovery;
        },
        existingCustomer () {
            return this.$store.state.car.existingCustomer;
        }
    },
    "methods": {
        previousPage () {
            this.$router.push({"name": "PhotosDamageRecovery"});
        },
        nextRouteAfterPlanning () {
            if (!this.existingCustomer) {
                return "ContactInfo";
            }
            return "Summary";
        }
    }
};
</script>
