<template>
    <v-row>
        <v-col>
            <v-list
                three-line>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-img :src="image" style="cursor: pointer;" @click="toWhatsApp"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            <h6 class="dark--text">Hulp nodig?</h6>
                        </v-list-item-title>
                        <v-list-item-subtitle>Neem bij vragen direct contact met ons op via WhatsApp. Bereikbaar op ma.
                            t/m
                            vr. van 07:00 uur tot 18:00 uur.
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
</template>

<script>
import image from "./../../../static/img/whatsapp.png";

export default {
    "name": "WhatsApp",
    "components": {},
    data () {
        return {
            image
        };
    },
    "methods": {
        toWhatsApp () {
            window.open(
                "https://api.whatsapp.com/send?phone=31886486586&text=Ik%20wil%20graag%20hulp%20bij%20het%20maken%20van%20een%20werkplaatsafspraak",
                "_blank"
            )
                .focus();
        }
    }
};
</script>
