<template>
    <span v-if="product">
        <span v-if="product.price"
              class="text-body-2">
            {{ formatPrice(product.price) }}
        </span>
        <span
            v-else-if="product.code.toLowerCase() === 'gratis'"
            class="text-body-2">Gratis</span>
        <span
            v-else
            class="text-body-2">-</span>
    </span>
</template>

<script>

export default {
    "name": "Price",
    "props": {
        "product": {
            "type": Object,
            "default": null
        }
    },
    "methods": {
        formatPrice (price) {
            return new Intl.NumberFormat(
                "nl-NL",
                {
                    "style": "currency",
                    "currency": "EUR"
                }
            ).format(price);
        }
    }
};

</script>
