<template>
    <v-form>
        <TitleText title="Vervangend vervoer" v-if="!dialogTitle"></TitleText>
        <v-divider v-if="!dialogTitle"></v-divider>
        <span class="text-decoration-underline text-uppercase" v-else>Vervangend vervoer</span>

        <v-radio-group
            @change="updatePathValue(
                ['currentTransportInfo.transport', 'Appointment.AppointmentInfo.ReplacementVehicle'],
                $event
                )"
            :value="transport.transport"
        >
            <v-radio
                label="Ja"
                :value="true"
            ></v-radio>
            <v-radio
                label="Nee"
                :value="false"
            ></v-radio>
        </v-radio-group>

        <div v-show="transport.transport">
            <TitleText title="Geef uw voorkeur vervangend vervoer door" v-if="!dialogTitle"></TitleText>
            <v-divider v-if="!dialogTitle"></v-divider>
            <span class="text-decoration-underline text-uppercase" v-else>
                Geef uw voorkeur vervangend vervoer door
            </span>

            <v-radio-group
                @change="updateTransportType($event)"
                :value="transport.type"
            >
                <v-radio
                    v-for="type in transportTypes"
                    :key="type.id"
                    :label="type.label"
                    :value="type.id"
                ></v-radio>
            </v-radio-group>
        </div>
    </v-form>
</template>

<script>

import TitleText from "@/components/general/TitelText";

export default {
    "name": "TransportForm",
    "props": {
        "dialogTitle": {
            "type": Boolean,
            "default": false
        }
    },
    data () {
        return {
            "transportTypes": [
                {
                    "id": 1,
                    "label": "Elektrische fiets"
                },
                {
                    "id": 2,
                    "label": "Haal- en brengservice"
                },
                {
                    "id": 3,
                    "label": "Vervangende auto (tegen serviceverhuur tarief)"
                }
            ]
        };
    },
    "components": {TitleText},
    "computed": {
        transport () {
            return this.$store.state.car.currentTransportInfo;
        }
    },
    "methods": {
        updatePathValue (object, value) {
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    object,
                    value
                }
            );
        },
        updateTransportType (value) {
            const transportType = this.transportTypes.find((type) => (type.id === value));
            if (!transportType) {
                return;
            }
            this.updatePathValue(
                "currentTransportInfo.type",
                value
            );
            this.updatePathValue(
                "Appointment.AppointmentInfo.ExternalReplacementVehicle",
                transportType.label
            );
        }
    }
};
</script>
