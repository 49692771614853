// Import "babel-polyfill";

import * as Integrations from "@sentry/integrations";
import * as Sentry from "@sentry/browser";

import App from "./App";
import ImageFallback from "vue-image-fallback";
import Vue from "vue";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import VueYouTubeEmbed from "vue-youtube-embed";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// Enable Sentry only for selected environments
if (process.env.VUE_APP_SENTRY === true) {
    Sentry.init({
        "dsn": "https://9f9971798bae453999bc787cd703eb87@o493015.ingest.sentry.io/5561364",
        "integrations": [
            new Integrations.Vue({
                Vue,
                "attachProps": true
            }),
            new Integrations.Dedupe()
        ],
        "environment": process.env.VUE_APP_NODE_ENV
    });
}
const VueUploadComponent = require("vue-upload-component");
Vue.component(
    "file-upload",
    VueUploadComponent
);

Vue.use(ImageFallback);

Vue.config.productionTip = false;
Vue.use(VueYouTubeEmbed);
Vue.component(
    "VueCtkDateTimePicker",
    VueCtkDateTimePicker
);

Vue.filter(
    "stripHTML",
    (value) => {
        const div = document.createElement("div");
        div.innerHTML = value;
        return div.textContent || div.innerText || "";
    }
);

/* eslint-disable no-new */
new Vue({
    "el": "#app",
    store,
    vuetify,
    router,
    "components": {App},
    "template": "<App/>"
}).$mount("#app");
