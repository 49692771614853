import api from "../../helpers/apiHandler";

const getDefaultState = () => ({
    "user": {},
    "loader": false
});

const state = getDefaultState();

const mutations = {
    SET_USER (newState, value) {
        for (const property of Object.keys(value)) {
            newState.user[property] = value[property];
        }
    },
    SET_LOADER (newState, value) {
        newState.loader = value;
    },
    API_FAILURE (newState, value) {
        return value;
    },
    RESET_STATE () {
        Object.assign(
            state,
            getDefaultState()
        );
    }
};

const getters = {
    "loader": (state) => state.loader
};

const actions = {
    async getSession ({commit}) {
        await api.get(
            "v1/session",
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "SET_USER",
                    response.data.user
                );
            })
            .catch((error) => commit(
                "API_FAILURE",
                error.message
            ));
    },
    putUser (context, value) {
        context.commit(
            "SET_USER",
            value
        );
    },
    setLoader (context, value) {
        context.commit(
            "SET_LOADER",
            value
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
