<template>
    <div>
        <v-alert v-for="alert in alerts" :key="alert" type="info" dense transition="false" color="#F4FF91"
                 class="rounded-0">
            <template v-slot:prepend>
                <v-icon color="darkgrey">mdi-information</v-icon>
            </template>
            <span class="darkgrey--text text-caption text-spacing-0 ml-2"><strong
                class="text-uppercase">Let op:</strong> {{ alert }}</span>
        </v-alert>
    </div>
</template>

<script>

export default {
    "name": "SidebarAlerts",
    "computed": {
        alerts () {
            return this.$store.state.car.alerts;
        }
    }
};

</script>

<style scoped>
.text-spacing-0 {
    letter-spacing: 0 !important;
}
</style>
