<template>
    <v-row>
        <v-col>
            <v-card
                class="rounded-lg mx-auto mb-3"
                flat
                color="secondary"
                v-for="review in reviews"
                :key="review.id"
            >
                <v-card-title>
                    {{ review.name }}
                </v-card-title>
                <v-card-subtitle>
                    {{ review.content }}<br/>
                    <sub>{{ review.car }}</sub>
                </v-card-subtitle>
                <v-card-text>
                    <v-rating
                        hover
                        length="5"
                        :value="review.rating"
                        size="25"
                        dense
                        readonly
                        color="darkblue"
                        background-color="#AFAFAF"
                        empty-icon="mdi-star"
                    ></v-rating>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    "name": "Reviews",
    "components": {},
    created () {
        this.$store.dispatch("reviews/getReviews");
    },
    "computed": {
        ...mapGetters({
            "reviews": "reviews/reviews"
        })
    }
};
</script>
